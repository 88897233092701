import React from "react"
import BssRevealSvg from "./partials/bss-reveal-logo"

const Header = ({ siteTitle, minified }) => (
  <header>
    <div className="row align-bottom align-justify">
      <div className="columns">
        <div className="logo--reveal">
          <BssRevealSvg/>
        </div>
      </div>
      <div className="columns shrink show-for-medium">
        <h4>A diverse, always-on Gen Z community, <br className="show-for-large"/>ages 18-24, from across North America <br className="show-for-large"/>who share the opinions and emotions <br className="show-for-large"/>behind the trend line.</h4>
      </div>
    </div>
    <div className="row">
      <div className="columns expand">
        {minified &&
          <h2>Success. <br/>Your information has been submitted.</h2>
        }
        {!minified &&
          <h2>Transform your understanding of <br className="show-for-large"/><strong>your next customer</strong> with rich, <br className="show-for-large"/>real-time insights.</h2>
        }
      </div>
    </div>
    <div className="row hide-for-medium">
      <div className="columns show-for-small">
        <h4>A diverse, always-on Gen Z community, <br className="show-for-large"/>ages 18-24, from across North America <br className="show-for-large"/>who share the opinions and emotions <br className="show-for-large"/>behind the trend line.</h4>
      </div>
    </div>
  </header>
)

export default Header
