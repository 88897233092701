// importing a logo SVG and embedding it in JSX
import React from "react"
import SVG from 'react-inlinesvg'
import { StaticQuery, graphql } from "gatsby"

const BssRevealSvg = () => (
  <StaticQuery
    query={graphql`
      query BssNavRevealQuery {
        file(relativePath: {eq: "bss-reveal-logo.svg"}) {
          publicURL
        }
      }
    `}
    render={data => {
      return (
        <SVG src={data.file.publicURL} />
      )
    }}
  />
)

export default BssRevealSvg
