import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"

const ThanksPage = () => (
  <>
  <Header minified="true"/>
  <Layout>
    <SEO title="Thanks" />
    <p>We have successfully captured your submission and will be in touch soon. <br/>Head on over to <a className="inline" href="https://www.bigspaceship.com">Bigspaceship.com</a> to learn more about how we analyze culture and behavior to find transformational insights for our clients.</p>
    <p>Stay Safe. Stay Healthy.</p>
  </Layout>
  </>
)

export default ThanksPage
